@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');



body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
}

h1,
h2,
h3 {
    font-family: 'Open Sans', sans-serif;
}

h1 {
    font-size: 48px;
    text-transform: uppercase;
}

h2 {
    font-size: 20px;
    text-transform: uppercase;
}

p {
    font-weight: 400;
}



button {
    cursor: pointer;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    color: white;
}

/* index.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.modal-content {
    position: relative;
    background-color: black;
    padding: 1rem;
    border-radius: 0.5rem;
    max-width: 90%;
    max-height: 75vh;
    overflow: hidden;
}

.modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

/* styles.css */
input[type="text"],
input[type="date"],
input[type="time"],
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #d1d5db;
    background-color: white;
    color: black;
    font-size: 1rem;
    height: 2.5rem;
    /* Ensure uniform height */
}

input::placeholder {
    color: black;
}

/* index.css */
/* .menu-item {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.menu-item.show {
    opacity: 1;
    transform: translateX(0);
} */


@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.menu-slide-in {
    animation: slideInLeft 0.3s ease-out;
}

.menu-item {
    animation: fadeIn 0.5s ease forwards;
    opacity: 0;
}

.menu-item:nth-child(1) {
    animation-delay: 0.2s;
}

.menu-item:nth-child(2) {
    animation-delay: 0.4s;
}

.menu-item:nth-child(3) {
    animation-delay: 0.6s;
}

.menu-item:nth-child(4) {
    animation-delay: 0.8s;
}

.menu-item:nth-child(5) {
    animation-delay: 1s;
}

.menu-item:nth-child(6) {
    animation-delay: 1.2s;
}

.menu-item:nth-child(7) {
    animation-delay: 1.4s;
}

.menu-item:nth-child(8) {
    animation-delay: 1.6s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}